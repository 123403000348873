@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.contestPlay {
    display: flex;
    color: $white;

    .morphisam {
        @include glassmorphism-effect;
        padding: 30px 10px;
    }

    .nav-link {
        &.active {
            border-radius: 0;
            border: none;
            outline: none;
            color: $white;
            box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
            background-image: linear-gradient(
                93deg,
                $gradient-one 0%,
                $gradient-two 100%
            ) !important;
        }
    }

    .nav-tabs {
        border-bottom: 1px solid #dddddd26;
        button {
            text-transform: uppercase;
            letter-spacing: 1px;
            box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
            background-color: #fafafa;
            color: $mate-black;
        }
    }

    .card {
        border-radius: 15px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(30px);
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
        margin-bottom: 15px;

        .card-image {
            img {
                height: 200px;
                // object-fit: contain;
                width: 100%;
                border-radius: 15px;
            }
        }
        .card-details {
            .card-title {
                padding-top: 10px;
                h3 {
                    font-size: 18px;
                    color: $mate-black;
                }
                p {
                    color: $mate-black;
                }
            }
            .card-footer {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                p {
                    color: $mate-black;
                    margin-bottom: 5px;
                }
                span {
                    color: $mate-black;
                }

                .btn-play {
                    background-image: linear-gradient(
                        93deg,
                        $gradient-one 0%,
                        $gradient-two 100%
                    ) !important;
                    color: $white;
                    margin-bottom: 0;
                }

                .upper-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 15px;
                }

                .bottom-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}

.contestque {
    .inner__headerdash {
        justify-content: center;
        flex-direction: column;

        .total__out__leveldata {
            position: relative;
            right: auto;
            margin-top: 20px;

            @media #{$extra-small-device} {
                margin-top: 0;
            }
        }

        .coinsdata{
            @media #{$extra-small-device} {
                margin: 40px auto;

            }
        }

        .bookmark_btn {
            position: absolute;
            right: 0px;
            top: 19px;

            @media #{$extra-small-device} {
                position: relative;
                margin-top: 0px;
                top: 0;
            }
        }
    }
}
